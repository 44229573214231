<template>
    <div class="position-relative">
        <div class="container my-auto d-flex align-items-center min-vh-100">
            <div class="d-flex col-md-10 col-xl-8 row mx-auto db-shadow container-auth">
                <div class="bg-primary col-12 col-sm-4 mx-auto d-flex align-items-center justify-content-center">
                    <NuxtLink to="/" class="mx-auto p-5 p-sm-1 p-md-3">
                        <Logo class="logo"/>
                    </NuxtLink>
                </div>
                <div class="col-12 col-sm-8 mx-auto p-5">
                    <div class="d-flex justify-content-end">
                        <ul class="list-unstyled d-flex navbar">
                            <li v-for="(item, index) in locales"
                                class="text-uppercase px-2 nav-item"
                                :class="{'border-end' : index < locales.length - 1}">
                                <span @click="changeLocale(item)" class="text-decoration-none nav-link px-2 cursor-pointer"
                                :class="{'router-link-active' : locale === item.code }">
                                    {{ item.code }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <slot/>
                </div>
            </div>
        </div>
        <FooterLinks/>
    </div>
</template>
<script setup>
import FooterLinks from "~/components/layouts/FooterLinks.vue";
import {useNuxtApp} from "nuxt/app";
import { setLocale } from '@vee-validate/i18n';
import Logo from "@/components/Logo";

const {locale, locales} = useI18n()
const preferredLocale = useNuxtApp().$i18n.getLocaleCookie() || useNuxtApp().$i18n.getBrowserLocale()

function changeLocale(newLocale) {
    useNuxtApp().$i18n.setLocale(newLocale.code);
    setLocale(newLocale.code);
    useDayjs().locale(newLocale.code)
}

onMounted(() => {
    changeLocale(preferredLocale)
})
</script>

<style scoped>
.text-footer {
    font-size: 0.9rem;
}
</style>